<template>
  <v-container
    fluid
    class="fill-height login_desktop"
    style="align-items: center"
  >
    <v-card class="mb-16" flat style="position: relative; border-radius: 25px">
      <v-row class="mb-16" align="center" justify="center">
        <v-col cols="12" align="center">
          <v-img
            :src="require('@/assets/img/icon_fujifilm.png')"
            max-height="120px"
            max-width="250px"
            contain
          ></v-img>
        </v-col>

        <v-col class="mt-10 pb-0" cols="12" align="center">
          <div
            class="black--text py-2"
            style="
              font-size: 18px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;
              letter-spacing: 0em;
            "
          >
            ขอบคุณสำหรับการสนับสนุนผลิตภัณฑ์ของเรา
          </div>
          <div
            class="black--text pt-0 pb-0"
            style="
              font-size: 18px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;
              letter-spacing: 0em;
            "
          >
            หากมีข้อสงสัยประการใดสามารถติดต่อเราได้ที่
          </div>
        </v-col>

        <v-col class="pt-2" cols="12" align="center">
          <div
            class="black--text py-2"
            style="
              font-size: 16px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;
              letter-spacing: 0em;
            "
          >
            บริษัท ฟูจิฟิล์ม บิสซิเนส อินโนเวชั่น (ประเทศไทย) จำกัด
          </div>
          <div
            class="black--text pt-0 pb-2"
            style="
              font-size: 16px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;
              letter-spacing: 0em;
            "
          >
            123 อาคารซันทาวเวอร์ส เอ ชั้น 23-26 ถนนวิภาวดี-รังสิต จอมพล จตุจักร
            กรุงเทพฯ 10900
          </div>

          <div
            class="black--text pt-0 pb-2"
            style="
              font-size: 16px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;
              letter-spacing: 0em;
            "
          >
            โทร: 02-660-8000 แฟกซ์: 02-617-6748-9 Call Center: 02-660-8400
          </div>
        </v-col>

        <v-col class="mt-12" cols="12" align="center">
          <div
            class="black--text py-2"
            style="
              font-size: 18px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;
              letter-spacing: 0em;
            "
          >
            Thank you for trusting and choosing our product
          </div>
          <div
            class="black--text pt-0 pb-2"
            style="
              font-size: 18px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;
              letter-spacing: 0em;
            "
          >
            Should you have other query or concern, please contact us at
          </div>
        </v-col>

        <v-col cols="12" class="py-0" align="center">
          <div
            class="black--text pb-2"
            style="
              font-size: 16px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;
              letter-spacing: 0em;
            "
          >
            FUJIFILM Business Innovation (Thailand) Co., Ltd.
          </div>
          <div
            class="black--text pt-0 pb-2"
            style="
              font-size: 16px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;
              letter-spacing: 0em;
            "
          >
            123 Suntowers A Building, 23rd-26th Floor, Vibhavadi-Rangsit Road,
            Chomphon, Chatuchak, Bangkok 10900
          </div>

          <div
            class="black--text pt-0 pb-2"
            style="
              font-size: 16px;
              font-style: normal;
              font-weight: 450;
              line-height: 25px;
              letter-spacing: 0em;
            "
          >
            Tel: 02-660-8000 Fax: 02-617-6748-9 Call Center: 02-660-8400
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},

  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
